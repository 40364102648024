import React from "react";
import { Helmet, HelmetProps } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { SeoModelQuery } from "../../graphql-schema-types";

type SeoProps = {
  description?: string | null;
  lang?: string;
  meta?: JSX.IntrinsicElements["meta"][];
  title?: string | null;
  image?: string;
};

export default function Seo({
  description = "",
  lang = "sv",
  image,
  meta = [{ name: "", content: "" }],
  title,
}: SeoProps) {
  const { site }: SeoModelQuery = useStaticQuery(
    graphql`
      query SeoModel {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site?.siteMetadata?.description;
  const metaImageUrl =
    image &&
    (image.startsWith("/") && !image.startsWith("//")
      ? `${HOSTNAME}/${image}`
      : image);
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title ?? site?.siteMetadata?.title ?? ""}
      titleTemplate={`%s | ${site?.siteMetadata?.title}`}
      meta={
        [
          {
            name: `description`,
            content: metaDescription ?? undefined,
          },
          {
            property: `og:title`,
            content: title ?? undefined,
          },
          {
            property: `og:description`,
            content: metaDescription ?? undefined,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          metaImageUrl && {
            property: `og:image`,
            content: metaImageUrl,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: site?.siteMetadata?.author ?? undefined,
          },
          {
            name: `twitter:title`,
            content: title ?? undefined,
          },
          {
            name: `twitter:description`,
            content: metaDescription ?? undefined,
          },
          metaImageUrl && {
            name: "twitter:image",
            content: metaImageUrl,
          },
        ]
          .concat(
            //@ts-ignore: this is the correct type
            meta
          )
          .filter((item) => item) as HelmetProps["meta"]
      }
    />
  );
}
